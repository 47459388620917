import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useParams } from 'react-router-dom';
import * as pickDropConfig from '../config/city.json';
import { useEffect, useState } from 'react';
import Select from "react-dropdown-select";
import getCars from '../config/cars';
import * as _ from 'lodash';
import * as charges from '../config/charges.json';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Collapsible from 'react-collapsible';
import { SuperSEO } from 'react-super-seo';

function ServiceFinder() {
    const { serviceType, location } = useParams();
    const
        [cover, setCover] = useState<string>(process.env.PUBLIC_URL + '/image/bg_2.jpg'),
        [isLoading, setLoading] = useState<Boolean>(true),
        [isSelfDrive, setIsSelfDrive] = useState<Boolean>(true),
        [selectedPickup, setPickup] = useState<string>(''),
        [selectedDropoff, setDropoff] = useState<string>(''),
        [pickupPoints, setPickupPoints] = useState<any[]>([]),
        [dropoffPoints, setDropoffPoints] = useState<any[]>([]),
        [totalDays, setTotalDays] = useState<number>(1),
        [pickupDate, setPickupDate] = useState<any>(new Date()),
        [dropoffDate, setDropoffDate] = useState<any>(new Date(new Date().setDate(new Date().getDate() + 1)));

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsSelfDrive(serviceType === 'self-driving-car-rental');
        setPickAndDrop(_.startCase(location));
        setCoverImage(_.startCase(location));
        setLoading(true);
    }, [serviceType, location]);

    function getTitle() {
        return `${_.startCase(serviceType)} Services In ${_.startCase(location)}`;
    }

    function isOvernightChargeApplicable() {
        return !isSelfDrive && getDays() > 1;
    }

    function setCoverImage(city: any) {
        let coverImage = pickDropConfig[city as keyof typeof pickDropConfig].cover;
        setCover(coverImage);
    }

    function setPickAndDrop(city: any) {
        let pickupPoints: any[] = [], dropoffPoints: any[] = [];
        pickDropConfig[city as keyof typeof pickDropConfig].pickup.forEach((pickup, index) => {
            pickupPoints.push({ id: index, name: pickup })
        });
        pickDropConfig[city as keyof typeof pickDropConfig].dropoff.forEach((pickup, index) => {
            dropoffPoints.push({ id: index, name: pickup })
        });
        setPickupPoints(pickupPoints);
        setDropoffPoints(dropoffPoints);
    }

    function getDays() {
        if (dropoffDate.toLocaleDateString() == pickupDate.toLocaleDateString())
            return 1
        const diffTime = Math.abs(dropoffDate - pickupDate);
        const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return isSelfDrive ? days : days + 1;
    }

    function getOvernightCharges() {
        if (isOvernightChargeApplicable()) {
            return selectedPickup ? charges[selectedPickup as keyof typeof charges]['Overnight Charges'] * (getDays() - 1) : 0;
        }
        return 0;
    }

    function getTotal(vehicle: any) {
        let total = 0,
            pickUpCharges = selectedPickup ? charges[selectedPickup as keyof typeof charges]['Convenience Fee'] : 0,
            dropOffCharges = selectedDropoff ? charges[selectedDropoff as keyof typeof charges]['Convenience Fee'] : 0,
            platformCharges = (selectedPickup && selectedDropoff) ? charges[selectedPickup as keyof typeof charges]['Platform Charges'] + charges[selectedDropoff as keyof typeof charges]['Platform Charges'] : 0;

        total = isSelfDrive ? (vehicle.Charges * getDays()) + pickUpCharges + dropOffCharges + platformCharges : (vehicle.Charges * getDays()) + platformCharges + getOvernightCharges();
        return total;
    }

    function getMessage(vehicle: any) {
        if (pickupDate && dropoffDate && selectedPickup && selectedDropoff) {
            let message = `Hello AapliGaadi,\nI am intrested in ${vehicle.Brand}, ${vehicle.Name}.\n\nBooking request details:\nStart Date & Location: ${pickupDate.toLocaleDateString()} (${selectedPickup})\nEnd Date & Location: ${dropoffDate.toLocaleDateString()} (${selectedDropoff})\n\n\nTotal Days: ${getDays()}\nTotal Fare: ${getTotal(vehicle)}/-`;

            return encodeURIComponent(message);
        }
        return null;
    }

    return (
        <>
            <SuperSEO
                title={`Aapli Gaadi: Best ${getTitle()}`}
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <div className="hero-wrap " style={{ backgroundImage: `url('${cover}')` }} data-stellar-background-ratio="0.5">

                <div className="container">
                    <div className="row no-gutters slider-text justify-content-start align-items-center justify-content-center">
                        <div className="col-lg-8 ">
                            <div className="text w-100 text-center mb-md-5 pb-md-5">
                                <h1 className="mb-4">
                                    {
                                        getTitle()
                                    }
                                </h1>
                                <p style={{ fontSize: '18px' }}>
                                    Drive unlimited kilometers starting as low as 1500/day onwards with <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 featured-top">
                            <div className="row no-gutters">
                                <div className="row bg-primary" style={{ borderRadius: '15px', padding: '20px', minWidth: '100%' }} id="trip_details">
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label htmlFor="" className="label">Pick-up location</label>
                                        <Select className={'form-control'} values={[]} labelField='name' searchable={true} valueField='name' options={pickupPoints} onChange={(values) => { setPickup(values[0].name) }} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label htmlFor="" className="label">Drop-off location</label>
                                        <Select className={'form-control'} values={[]} labelField='name' searchable={true} valueField='name' options={dropoffPoints} onChange={(values) => { setDropoff(values[0].name) }} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                        <label htmlFor="" className="label">Pick-up date</label><br />
                                        <DatePicker className={'form-control'} onChange={setPickupDate} value={pickupDate} minDate={new Date()} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                        <label htmlFor="" className="label">Drop-off date</label><br />
                                        <DatePicker className={'form-control'} onChange={setDropoffDate} value={dropoffDate} minDate={isSelfDrive ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date()} />
                                    </div>
                                    <div className='col-md-4'></div>
                                    <div className="col-md-4 align-items-center">
                                        <br />
                                        <a href="#cars" className="btn btn-secondary py-3 px-4 form-control" > Rent A Car Now</a>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                                {/* <div className="col-md-8 align-items-center">
                                    <div className="services-wrap rounded-right w-100">
                                        <h3 className="heading-section mb-4">Better Way to Rent Your Perfect Cars</h3>
                                        <div className="row d-flex mb-4">
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Choose Your Pickup Location</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-handshake"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Select the Best Deal</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-rent"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Reserve Your Rental Car</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="ftco-section ftco-no-pt bg-light" id="cars">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 heading-section text-center  mb-5">
                            <h2 className="mb-2">Available Vehicles</h2>
                        </div>
                    </div>
                    {
                        _.filter(getCars(), (car) => { return (isSelfDrive ? car.isSelfDrive : !car.isSelfDrive) && car.Locations.indexOf(_.startCase(location)) > -1 }).map((vehicle: any, idx: number) =>
                            <div className="car-wrap rounded" key={idx}><div className="row">
                                <div className="col-md-4" style={{ padding: '2%' }}>
                                    <img className='img' style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/image/cars/${vehicle.Image}`} />
                                    <p style={{ textAlign: 'center' }}>
                                        <img style={{ height: '25px' }} src="/icons/location.png" />
                                        &nbsp;&nbsp;{vehicle.Hub}&nbsp;&nbsp;
                                    </p>
                                </div>
                                <div className="col-md-8" style={{ padding: '2%' }}>
                                    <div className='text'>
                                        <h2 className="mb-0">{vehicle.Brand}, {vehicle.Name}</h2>

                                        <div className="row">
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/fuel.svg" />
                                                &nbsp;&nbsp;{vehicle.Details.Fuel}&nbsp;&nbsp;
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/transmission.png" />
                                                &nbsp;&nbsp;{vehicle.Details.Transmission}&nbsp;&nbsp;
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/luggage.svg" />
                                                &nbsp;&nbsp;{vehicle.Details['Luggage Capacity']} bags&nbsp;&nbsp;
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/seat.svg" />
                                                &nbsp;&nbsp;{vehicle.Details['Seating Capacity']} Seater&nbsp;&nbsp;
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/milage.png" />
                                                &nbsp;&nbsp;{vehicle.Details['Avg/Ltr']} kmpl&nbsp;&nbsp;
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/price.png" />
                                                &nbsp;&nbsp;{vehicle.Charges}/ {vehicle.UOM}&nbsp;&nbsp;
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/road.png" />
                                                &nbsp;&nbsp;{vehicle['KM Included']} KMs/Day Included&nbsp;&nbsp;
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xs-12' style={{ padding: '5px' }}>
                                                <img style={{ height: '30px' }} src="/icons/extra.png" />
                                                &nbsp;&nbsp;{vehicle['Extra KM Charges'] > 0 ? `${vehicle['Extra KM Charges']}Rs. /KM` : 'N/A'} &nbsp;&nbsp;
                                            </div>

                                        </div>

                                        <p className="d-flex mb-0 d-block">
                                            <a href="#trip_details" className="py-2 mr-1">{pickupDate && dropoffDate && selectedPickup && selectedDropoff ? `Total: ${getTotal(vehicle)}/- Rs.` : 'Please select pick up and drop location to proceed with the booking.'}</a>
                                            {pickupDate && dropoffDate && selectedPickup && selectedDropoff && <a href={`https://wa.me/917208388380/?text=${getMessage(vehicle)}`} target="_blank" className="btn btn-primary py-2 ml-1">Book now</a>}
                                        </p>
                                        {pickupDate && dropoffDate && selectedPickup && selectedDropoff && <div className='"d-flex'>
                                            <Collapsible trigger="View Price Breakup">
                                                <div>
                                                    <ul>
                                                        <li>Fleet Charges: {vehicle.Charges * getDays()} Rs.</li>
                                                        {isSelfDrive && <li>
                                                            Location Convenience Charges
                                                            <ul>
                                                                <li>Pick up charges : {selectedPickup && charges[selectedPickup as keyof typeof charges]['Convenience Fee']} Rs.</li>
                                                                <li>Drop off charges : {selectedDropoff && charges[selectedDropoff as keyof typeof charges]['Convenience Fee']} Rs.</li>
                                                            </ul>
                                                        </li>}
                                                        {isOvernightChargeApplicable() && <li>Overnight charges : {getOvernightCharges()} Rs.</li>}
                                                        <li>
                                                            Platform Handling Fees: {selectedPickup && selectedDropoff && charges[selectedPickup as keyof typeof charges]['Platform Charges'] + charges[selectedDropoff as keyof typeof charges]['Platform Charges']} Rs. (*Introductory offer)
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Collapsible>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            </div>)
                    }
                </div>
            </section>
        </>
    )
}

export default ServiceFinder