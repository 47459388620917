function getCars() {
    return [
        {
            "Brand": "Maruti Suzuki",
            "Name": "Wagon R",
            "Charges": 2000,
            "UOM": "Day",
            "Start Time": "9 AM",
            "End Time": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KM Included": "Unlimited",
            "Extra KM Charges": 0,
            "Image": "wagonR.jpeg",
            "Details": {
                "Fuel": "Petrol",
                "Seating Capacity": 5,
                "Luggage Capacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": 15
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Alto K10",
            "Charges": 1500,
            "UOM": "Day",
            "Start Time": "9 AM",
            "End Time": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KM Included": "Unlimited",
            "Extra KM Charges": 0,
            "Image": "altok10.jpeg",
            "Details": {
                "Fuel": "Petrol",
                "Seating Capacity": 4,
                "Luggage Capacity": 2,
                "Transmission": "Manual",
                "Avg/Ltr": 18
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Wagon R",
            "Charges": 2200,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KM Included": "Unlimited",
            "Extra KM Charges": 0,
            "Image": "wagonR_new.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "Seating Capacity": 5,
                "Luggage Capacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": 15
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Celerio",
            "Charges": 2200,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KM Included": "Unlimited",
            "Extra KM Charges": 0,
            "Image": "celerio.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "Seating Capacity": 5,
                "Luggage Capacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": 15
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga",
            "Charges": 2700,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KM Included": "Unlimited",
            "Extra KM Charges": 0,
            "Image": "ertiga_new.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "Seating Capacity": 6,
                "Luggage Capacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": 15
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Dzire",
            "Charges": 2500,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KM Included": "Unlimited",
            "Extra KM Charges": 0,
            "Image": "dzire.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "Seating Capacity": 5,
                "Luggage Capacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": 20
            }
        },
        {
            "Brand": "Hyundai",
            "Name": "Aura",
            "Charges": 3800,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli"
            ],
            "Hub": "Malvan, Maharashtra",
            "isSelfDrive": false,
            "KM Included": 150,
            "Extra KM Charges": 15,
            "Image": "aura.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "Seating Capacity": 5,
                "Luggage Capacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": 28
            }
        },
        {
            "Brand": "Mahindra",
            "Name": "Xylo",
            "Charges": 4800,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli"
            ],
            "Hub": "Malvan, Maharashtra",
            "isSelfDrive": false,
            "KM Included": 150,
            "Extra KM Charges": 18,
            "Image": "xylo.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "Seating Capacity": 7,
                "Luggage Capacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": 12
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innova",
            "Charges": 4900,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli"
            ],
            "Hub": "Malvan, Maharashtra",
            "isSelfDrive": false,
            "KM Included": 150,
            "Extra KM Charges": 18,
            "Image": "innova.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "Seating Capacity": 7,
                "Luggage Capacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": 12
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga",
            "Charges": 4200,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KM Included": 150,
            "Extra KM Charges": 22,
            "Image": "ertiga_new.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "Seating Capacity": 6,
                "Luggage Capacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": 15
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innvova",
            "Charges": 4800,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KM Included": 150,
            "Extra KM Charges": 25,
            "Image": "innova.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "Seating Capacity": 7,
                "Luggage Capacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": 12
            }
        },
        {
            "Brand": "Force",
            "Name": "Tempo Traveller (AC)",
            "Charges": 8500,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KM Included": 150,
            "Extra KM Charges": 38,
            "Image": "traveller-ac.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "Seating Capacity": 20,
                "Luggage Capacity": 6,
                "Transmission": "Manual",
                "Avg/Ltr": 7
            }
        },
        {
            "Brand": "Force",
            "Name": "Tempo Traveller (Non-AC)",
            "Charges": 6500,
            "UOM": "Day",
            "Start Time": "12 AM",
            "End Time": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KM Included": 150,
            "Extra KM Charges": 28,
            "Image": "traveller.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "Seating Capacity": 20,
                "Luggage Capacity": 6,
                "Transmission": "Manual",
                "Avg/Ltr": 8
            }
        }
    ]
}
export default getCars;